<template>
  <b-sidebar
    id="add-new-role-sidebar"
    :visible="isAddNewRoleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-role-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Role
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          
          <!-- Role name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="roleData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="roleData.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

           <!-- Modules -->
          <validation-provider
            #default="validationContext"
            name="Modules"
            rules="required"
          >
            <b-form-group
              label="Modules"
              label-for="modules"
            >
              <b-form-select
                input-id="modules"
                v-model="roleData.modules"
                :options="modules"
                value-field="_id"
                text-field="name"
                multiple
                :select-size="6"
                :state="getValidationState(validationContext)"
              />
              
              <template #code>
                {{ codeMultiple }}
              </template>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

            </b-form-group>
          </validation-provider>

          
         
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
          
        </b-form>
      </validation-observer>
      
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'
import { codeMultiple } from '../../../forms/form-element/form-select/code'
import moduleStoreModule from '../../../pages/modules/moduleStoreModule'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRoleSidebarActive',
    event: 'update:is-add-new-role-sidebar-active',
  },
  props: {
    isAddNewRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
    
  },
  data() {
    return {
      required,
      alphaNum,
      roleExist: '',
      codeMultiple,
    }
  },
  setup(props, { emit }) {
    const MODULE_APP_STORE_MODULE_NAME = 'app-module'
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)
    onUnmounted(() => {
      if (store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.unregisterModule(MODULE_APP_STORE_MODULE_NAME)
    })

    const modules = ref([])
    store.dispatch('app-module/fetchModules')
      .then(response => { 
        modules.value = response.data.modules})

    const blankRoleData = {
      name: '',
      description: '',
      modules: []
    }

    const roleData = ref(JSON.parse(JSON.stringify(blankRoleData)))
    const resetroleData = () => {
      roleData.value = JSON.parse(JSON.stringify(blankRoleData))
    }

    const onSubmit = () => {
      
      var modules = []
      roleData.value.modules.forEach((item) => {
         var module = {module:item, create: 'false', update: 'false', view: 'false', delete: 'false'} 
          modules.push(module)
      })
      
      roleData.value.modules = modules

      store.dispatch('app-role/addRole', roleData.value)
        .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-role-sidebar-active', false)
        }).catch(msg => {
           console.log('error submit: ', msg)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetroleData)

    return {
      roleData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      modules
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-role-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
