<template>

  <div>

    <role-list-add-new
      :is-add-new-role-sidebar-active.sync="isAddNewRoleSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <roles-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <div>
                <b-button
                  variant="primary"
                  @click="isAddNewRoleSidebarActive = true"
                >
                  <span class="text-nowrap">Add Role</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refRoleListTable"
        class="position-relative"
        :items="fetchRoles"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <!-- Column: Name -->
        <template #cell(name)="data">
          <b-media vertical-align="center" >
            <p class="firstletter">{{ data.item.name }}</p>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-roles-view', params: { id: data.item._id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <div>
              <b-dropdown-item :to="{ name: 'apps-roles-edit', params: { id: data.item._id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
            </div>

            <div>
              <b-dropdown-item  @click="removeRole(data.item._id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </div>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRoles"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RolesListFilters from './RolesListFilters.vue'
import useRolesList from './useRolesList'
import roleStoreModule from '../roleStoreModule'
import RoleListAddNew from './RoleListAddNew.vue'

export default {
  components: {
    RolesListFilters,
    RoleListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const ROLE_APP_STORE_MODULE_NAME = 'app-role'

    // Register module
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
    })

    const isAddNewRoleSidebarActive = ref(false)
    
    const statusOptions = [
      { label: 'True', value: true },
      { label: 'False', value: false },
    ]

    const {
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalRoles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRoleListTable,
      refetchData,
      recordDeleted,

      // UI
           
      resolveRoleStatusVariant,

      // Extra Filters
    
      statusFilter,
    } = useRolesList()

    
    return {

      // Sidebar
      isAddNewRoleSidebarActive,

      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalRoles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRoleListTable,
      refetchData,
      recordDeleted,
      // UI
   
      resolveRoleStatusVariant,
      statusOptions,

      // Extra Filters
            
      statusFilter,
    }
  },
  methods: {
    removeRole(Id) {
      store.dispatch('app-role/removeRole', { id: Id })
        .then(() => {
          this.recordDeleted = !this.recordDeleted
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Role removed',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.firstletter::first-letter{
  color: #efb810 !important;
  font-size:2em;
	font-family:serif;
  text-transform: capitalize;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>